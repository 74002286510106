import landing from "@/components/sharedComponents/landing/landing.component";
import { policyList } from "@/enums/permissions";
import { LandingItem } from "@/interfaces/landingItem";
import { metrcEnabled } from "@/router.utils";
import { PageNavAction } from "@/types/types";
import { BooleanCheck } from "helix-vue-components";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./MetrcLanding.template.vue";

@Component({
  mixins: [Template],
  components: {
    landing
  }
})
export default class MetrcLandingComponent extends Vue {
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public landingItems: LandingItem[] = [];
  public items: LandingItem[] = [];
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  protected beforeMount() {
    this.setPageNav(null);
    this.landingItems = [
      {
        namedRoute: "tag-manager",
        translationString: "tag_manager.title",
        visibleCondition: () =>
          this.hasPermission(policyList.viewModifyTagManager)
      },
      ...(metrcEnabled()
        ? [
            {
              translationString: "Items Manager",
              namedRoute: "item-manager",
              visibleCondition: () =>
                this.hasPermission(policyList.viewMetrcItems)
            },
            {
              translationString: "Strains Reconciliation",
              namedRoute: "strains-reco",
              visibleCondition: () =>
                this.hasPermission(policyList.viewModifyStrainReconciliation)
            },
            {
              translationString: "Inventory Reconciliation",
              namedRoute: "inv-reco-list",
              visibleCondition: () =>
                this.hasPermission(policyList.viewModifyInventoryReconciliation)
            },
            {
              translationString: "Retail Sales Reconciliation",
              namedRoute: "retail-sales-reco",
              visibleCondition: () =>
                this.hasPermission(policyList.viewModifySalesReconciliation)
            }
          ]
        : [])
    ];
  }
}
